<template>
  <v-container>
    <v-row>
      <v-col>
        <BusinessRulesTable />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BusinessRulesTable from './components/BusinessRulesTable.vue'

export default {
  components: {
    BusinessRulesTable,
  },
}
</script>
