<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="rules"
      :items-per-page="rulesPerPage"
      :footer-props="{ 'items-per-page-options': [10, 20] }"
      :server-items-length="rulesTotal"
      sort-by="name"
      :single-select="true"
      :loading="isLoading"
      item-key="id"
      disable-sort
      dense
      @update:options="updateOptions"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Business Rules</v-toolbar-title>
          <v-divider class="mx-4" inset vertical />
          <v-spacer />
          <v-btn color="primary" dark class="mb-2" @click="createItem">
            New Rule
          </v-btn>
          <v-btn color="grey" dark class="mb-2 ml-2" @click="refresh">
            Refresh
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          $mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)"> $mdi-delete </v-icon>
      </template>
    </v-data-table>
    <BusinessRulesTableDetail :rule="selectedItem" @change="saveItem" />
  </div>
</template>

<script>
import {
  getRulesJSON,
  getRuleJSON,
  updateRuleJSON,
  createRuleJSON,
} from '@/api/business-rules/rule-editor'
import { deleteRule } from '@/api/business-rules/rules'
import BusinessRulesTableDetail from './BusinessRulesTableDetail'

export default {
  components: {
    BusinessRulesTableDetail,
  },
  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Type', value: 'type' },
      { text: 'Description', value: 'description' },
      { text: 'Entities', value: 'entities' },
      { text: 'Status', value: 'status' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
    ],
    isLoading: false,
    selectedItem: undefined,
    tableOptions: undefined,
    rules: [],
    rulesPerPage: 10,
    rulesTotal: 0,
  }),
  methods: {
    async updateOptions(options) {
      this.tableOptions = options
      this.isLoading = true

      const { rules, rulesPerPage, rulesTotal } = await getRulesJSON({
        perPage: options.itemsPerPage <= 0 ? 1000 : options.itemsPerPage,
        page: options.page,
        orderByField: options.sortBy[0],
        orderBy: options.sortDesc[0] ? 'desc' : 'asc',
      })

      this.rules = rules
      this.rulesPerPage = rulesPerPage
      this.rulesTotal = rulesTotal

      this.isLoading = false
    },
    async editItem(item) {
      this.isLoading = true
      this.selectedItem = await getRuleJSON(item.id)
      this.isLoading = false
    },
    async saveItem(item) {
      if (item.id) {
        await updateRuleJSON(item.id, item)
      } else {
        await createRuleJSON(item)
      }

      await this.updateOptions(this.tableOptions)
    },
    createItem() {
      // this structure must match what the structure that the API is expecting.
      this.selectedItem = {
        name: 'New Rule',
        description: 'Description',
        status: 'active',
        type: 'Filter',
        entities: 'Account',
        rule: {},
      }
    },
    async deleteItem(item) {
      await deleteRule(item.id)
      await this.updateOptions(this.tableOptions)
    },
    async refresh() {
      await this.updateOptions(this.tableOptions)
    },
  },
}
</script>
