<template>
  <ApDialog
    v-if="rule"
    v-model="dialog"
    is-dividers-visible
    :title="rule.name"
    :actions="dialogActions"
    @save="save"
  >
    <template #content>
      <v-jsoneditor :value="ruleData" class="editor" @input="ruleDataChanged" />
    </template>
  </ApDialog>
</template>

<script>
import { cloneDeep, isNil } from 'lodash-es'
import VJsoneditor from 'vue-jsoneditor'
import ApDialog from '@/components/common/ApDialog'

export default {
  components: {
    VJsoneditor,
    ApDialog,
  },
  props: {
    rule: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    dialog: false,
    dialogActions: [
      {
        event: 'save',
        label: 'Save',
        text: true,
        color: 'primary',
      },
    ],
    ruleData: undefined,
    ruleJson: undefined,
  }),
  watch: {
    rule(value) {
      this.ruleData = cloneDeep(value)
      this.dialog = !isNil(value)
    },
  },
  methods: {
    ruleDataChanged(value) {
      this.ruleJson = cloneDeep(value)
    },
    save() {
      this.$emit('change', this.ruleJson)
      this.dialog = false
    },
  },
}
</script>
