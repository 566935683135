import http from '../http'
import { mapToArray, mapToObject } from '../helpers/mappers'

const RULES_REQUEST_PATHS = {
  per_page: 'perPage',
  page: 'page',
  order_by_field: 'orderByField',
  order: 'order_by',
}

const RULE_RESPONSE_PATHS = {
  id: 'id',
  name: 'name',
  type: 'type',
  description: 'description',
  entities: 'entities',
  status: 'status',
  rule: 'rule',
}

const RULES_PAGINATION_RESPONSE_PATHS = {
  rulesPerPage: 'per_page',
  rulesTotal: 'total',
}

export const getRulesJSON = (payload) => {
  const params = mapToObject(payload, RULES_REQUEST_PATHS)

  return http.$get('/rule/rules', { params }).then((response) => {
    return {
      rules: mapToArray(response.data, RULE_RESPONSE_PATHS),
      ...mapToObject(response.pagination, RULES_PAGINATION_RESPONSE_PATHS),
    }
  })
}

export const getRuleJSON = (ruleId) => http.$get(`/rule/rules/${ruleId}`)
export const createRuleJSON = (json) => http.$post('/rule/rules', json)
export const updateRuleJSON = (ruleId, json) =>
  http.$post(`/rule/rules/${ruleId}`, json)
